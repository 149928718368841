/**
 * 作者互动区域
 */

export default {
    init({data = {}}: Record<string, any>) {
        const {content, type = 'dt'} = data || {};
        // 根据type取正文数据
        const {title} = content?.[type] || {};

        return {
            // 聚合同步数据
            author: data.author || {},
            // 评论数
            comment: data.comment || {},
            // 点赞数
            praise: data.praise || {},
            // 正文标题
            richList: title || [],
            // 热榜数据
            shareHotInfo: data.shareHotInfo || {},
        };
    },
};
