/**
 * @file 这里输出RD同步数据集合，必要进行相应处理
 */

export default {
    init(data: Record<string, any>) {
        const {content, type = 'dt'} = data?.data || {};
        // 根据type取正文数据
        const {template} = content?.[type] || {};

        return {
            // 实验变量
            dtshareSmfw: data.data?.dtshare_smfw || {},
            // feed sid
            feedSid: data.data?.feed_id || {},
            // nid
            nid: data.data?.nid || '',
            // dtlanding旧动态-上文下图，默认新动态dynamic
            isOldDynamic: template === 'dtlanding',
        };
    },
};
