/**
 * @file 拖拽按钮事件改造
 */
import {store} from '@/lib/Store';
import {METHODS_POS} from '@/pages/fusion/utils';
import {sendUbcClick, sendUbcShow} from '@/lib/log';
import {DragSingle} from '@baidu/wise-invoke-drag';
import mcpMethod from './mcpInvoke.module';

class dragButton {
    constructor() {};

    /**
     * 初始化
     */
    initDrag() {
        const ivkFlag = store.getState('ivkFlag');

        if (!ivkFlag.show_dragButton) {
            return false;
        }

        const {
            title,
            logo,
            background,
            boxShadow,
        } = store.getState('dragButton');

        // 初始化拖拽
        new DragSingle({
            title,
            logo,
            background,
            boxShadow,
            backflow: () => {
                // 点击回调
                this.click();
            },
        });

        // 展现日志
        this.show();

        // 渲染完之后，加上微信开放标签
        setTimeout(() => {
            const drag = document.querySelector('.common-wrap');
            const wxTag = document.querySelector('.dragPosBtn');
            if (drag && wxTag) {
                drag.appendChild(wxTag);
                // @ts-ignore
                wxTag.style.width = 'initial';
                // @ts-ignore
                wxTag.style.height = 'initial';
            }
        }, 100);
    };

    /**
     * 展现
     */
    show() {
        // 展现日志
        sendUbcShow({
            value: 'dragButton',
        })
    }

    /**
     * 点击
     */
    click() {
        // 点击日志
        sendUbcClick({
            value: 'dragButton',
        })

        const posKey = store.getState('mcpInvoke').posKey || {};
        const globalPos = posKey.Global || {};

        // 点击调起
        mcpMethod.invoke({
            pos: globalPos.drag,
        })
    };
};

export default new dragButton();
