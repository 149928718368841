/**
 * @file wxopen事件
 */

class wxInit {
    constructor() {
    };

    initWxopen(payload: Record<string, unknown>) {
        window.wx.config({
            debug: false,
            appId: payload?.appId,
            timestamp: payload?.timestamp,
            nonceStr: payload?.nonceStr,
            signature: payload?.signature,
            jsApiList: [
                'onMenuShareWeibo',
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
            ],
            openTagList: ['wx-open-launch-app'],
        });
    }
};

export default new wxInit();