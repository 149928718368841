/**
 * @file services/index.ts
 * @author chenglong13 <chenglong13@baidu.com>
 */
import axios from 'axios';
import axiosJsonp from 'axios-jsonp';
import {getQueries} from '@/lib/utils';
import {spyError} from '@/lib/utils/spyLog';
import {sendCompasslog} from '@/lib/log';

export const getShareFeedData = () => {
    const query = getQueries() || {};
    // axios jsonp
    return new Promise((resolve, reject) => {
        (axios as any)({
            url: 'https://mbd.baidu.com/newspage/api/dtShareFeed',
            adapter: axiosJsonp,
            params: {
                page: 'fusionpage',
                nid: query?.nid || '',
                sourceFrom: query?.sourceFrom || '',
                _refluxos: query?._refluxos || '',
            },
            callbackParamName: 'cb',
        })
        .then((res: any) => {
            try {
                const resData = res.data || {};
                if (resData && resData.errno === 0) {
                    // sharefeed数据
                    resolve(resData || {});
                } else {
                    // Compass errno返回异常
                    /* istanbul ignore next */
                    sendCompasslog({
                        value: 'fusionPageRelate',
                        errorType: 'errnoErr',
                        errno: resData.errno,
                    });
                }
            } catch (e: any) {
                // spy接口异常
                /* istanbul ignore next */
                spyError({
                    info: {
                        msg: e?.message,
                        errno: res.data?.errno,
                    },
                    group: 'fusionpage_request',
                });
            }
        })
        .catch((err: any) => {
            // Compass 接口请求异常
            sendCompasslog({
                value: 'fusionPageRelate',
                errorType: 'requestErr',
                msg: err?.message,
                stack: err?.stack,
            });
            reject(err);
        })
    })
}