/**
 * @file store 文件
 */
import {store} from '@/lib/Store';
import allActions from '../action';
import processInitData from './processInitData';
import {getQueries} from '@/lib/utils';

export const initStore = (data: any) => {
    const finalData = processInitData(data, getQueries());
    store.initData(finalData).addActions(allActions);

    return store;
};
