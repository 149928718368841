/**
 * @file mcp update store操作
 */
import {builder} from 'san-update';
import {STATUS_POS_KEY} from '../../utils';

export default [
    /**
     * @abstract builder，update store方法
     * 
     * mcp:updateMcpList 更新 mcpInvoke.mcpList
     */
    {
        eventName: 'mcp:updateMcpList',
        handler: (payload: Payload, {getState}: Store) => {
            const mcpList = getState('mcpInvoke.mcpList');
            return builder().set('mcpInvoke.mcpList', mcpList.concat(payload));
        }
    },

    /**
     * @abstract 统一根据mcp返回更新对应位置展示/交互状态
     */
    {
        eventName: 'mcp:updateIvkFlag',
        handler: (payload: Payload) => {
            const invokePos = payload?.invokePos;
            return builder().set(`ivkFlag.${STATUS_POS_KEY[invokePos]}`, false);
        }
    },

    /**
     * @abstract 更新全局调起展示状态
     */
    {
        eventName: 'mcp:updateGlobalWxTagStatus',
        handler: (payload: Payload) => {
            return builder().set('ivkFlag.show_globalMask', payload?.globalMaskStatus);
        }
    },

    /**
     * @abstract 更新播放按钮wxtag展示状态/避免始终无法播放
     */
    {
        eventName: 'mcp:updatePlayWxTagStatus',
        handler: (payload: Payload) => {
            return builder().set('ivkFlag.show_playerPlayTag', payload?.playStatus);
        }
    },

    /**
     * @abstract 更新展开全文展示状态
     */
    {
        eventName: 'mcp:updateReadWxTagStatus',
        handler: (payload: Payload) => {
            return builder().set('ivkFlag.show_readAllTag', payload?.readStatus);
        }
    },

    /**
     * @abstract 更新posKey store
     */
    {
        eventName: 'mcp:updatePosKey',
        handler: (payload: Payload) => {
            return builder().set('mcpInvoke.posKey', payload?.posKey);
        }
    },

    /**
     * @abstract 设置store globalConf
     */
    {
        eventName: 'mcp:setGlobalConf',
        handler: (payload: Payload) => {
            return builder().set('mcpInvoke.globalConf', payload?.globalConf);
        }
    },

    /**
     * @abstract 更新是否展示开放标签状态
     */
    {
        eventName: 'mcp:updateShowWxTagFlag',
        handler: (payload: Payload) => {
            return builder().set('mcpInvoke.isShowWxOpenTag', payload?.wxOpen);
        }
    }
];
