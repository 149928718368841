/**
 * sharefeed store 
 */

import {envConf} from '@/pages/fusion/utils';

export default {
    init() {
        const appTitle = envConf.appTitle || '百度APP';
        const appName = envConf.appName;
        return {
            list: [],
            relate: {},
            posData: {},
            loading: true,
            isBottom: false,
            appName,
            context: {
                title: '精彩推荐',
                subTitle: '打开APP看更多精彩推荐',
                loadText: '正在加载...',
                endText: `看不够？进入${appTitle}尽情探索`,
                revealText: '页面到底了',
            }
        };
    },
};
