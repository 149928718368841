/**
 * @file mcp invoke公共方法
 */
import {
    getMcpPos,
} from '@/lib/utils';
import {store} from '@/lib/Store';
import * as ugInvoke from '@baidu/ug-invoke-app';
import {sendUbcShow} from '@/lib/log';
import {setMcpIntervalInfo, getExitInfo} from '@baidu/led-wx-open-tag/utils';
class mcpMethod {
    constructor() {
    };

    /**
     * @abstract 获取pos后调起
     * @param pos 需要调起的pos位
     * @param index 当前pos位，返回值(数组情况)中的第x个。单个调起位(非数组情况)无需
     */
    invoke(payload: {
        pos: string,
        index?: number,
    }) {
        const {pos, index = 0} = payload || {};
        const mcpInvoke = store.getState('mcpInvoke');
        const mcpItemPos = getMcpPos(pos, mcpInvoke.mcpList);

        // 调起mcp
        const hitMcp = mcpItemPos && !mcpItemPos.isBlock();

        // action_falg不满足先返回
        if (
            !mcpItemPos
            || !mcpItemPos.getActionFlag()
        ) {
            return;
        }

        // mcp控制可调起
        if (hitMcp) {
            mcpItemPos.index = index;
            mcpItemPos.execInvoke();
        } else {
            ugInvoke.invokeApp({
                appName: 'baiduboxapp',
                token: mcpItemPos.opt[index]?.token,
                scheme: mcpItemPos.opt[index]?.scheme,
                // @ts-ignore
                downloadUrl: 'https://boxer.baidu.com/scheme?source=1024510n&channel=1024510x',
            });
        }
    };

    /**
     * @abstract 用scheme替换href静默调起
     */
    slienceInvoke(payload: {
        time: number,
        scheme: string,
    }) {
        if (payload.time >= 0 && !getExitInfo('slienceInvoke_dt').isExit && payload.scheme) {
            // 存下展示
            setMcpIntervalInfo('slienceInvoke_dt', {
                click_interval: payload.time,
            });
            sendUbcShow({
                value: 'slienceInvoke',
            })
            location.href = payload.scheme;
        };
    }
};

export default new mcpMethod();
