/**
 * @file 常量
 */

// 集合
export * from './posKey';
export * from './ivkFlag';
export * from './request.mcp';

// 页面标识
export const LOG_PAGE = 'newdynamicShare';
