/**
 * @file mcp公共action
 * @abstract 这里只是迁移原始的开放标签组件dispatch的方法，勿在此处添加事件
 * @abstract 业务交互逻辑参考core/modules，走注入的方法，业务组件处理
 */
import {
    uaInfo,
    getMcpPos,
    copyToClipBoard,
} from '@/lib/utils';
import {sendUbcClick} from '@/lib/log';
import {MCP_POS_KEY} from '../../utils';
import mcpMethod from '../modules/mcpInvoke.module';

let wxOpenTagTime = 0;
let wxOpenLaunchClickTime = 0;

/**
 * @abstract 处理开放标签点击改变ivkFlag
 */
const firstWxCkSolve = (
    {posKey}: Record<string, any>,
    {getState, dispatch}: Store
) => {
    const ivkShowMask = getState('ivkFlag.show_globalMask');
    const ivkShowPlay = getState('ivkFlag.show_playerPlayTag');
    const ivkShowRead = getState('ivkFlag.show_readAllTag');

    switch (posKey) {
        case MCP_POS_KEY.Global.mask:
            if (ivkShowMask) {
                dispatch('mcp:updateGlobalWxTagStatus', {
                    globalMaskStatus: false,
                });
            }
            break;
        case MCP_POS_KEY.FusionPlayer.play:
            if (ivkShowPlay) {
                dispatch('mcp:updatePlayWxTagStatus', {
                    playStatus: false,
                });
            }
            break;
        case MCP_POS_KEY.AuthorInfo.readAll:
            if (ivkShowRead) {
                dispatch('mcp:updateReadWxTagStatus', {
                    readStatus: false,
                });
            }
            break;
    }
};

export default [
    /**
     * @abstract led-wx-open-tag组件调用，给标签加上extinfo
     */
    {
        eventName: 'mcp:addExtinfo',
        handler: (payload: Payload, {getState}: Store) => {
            const {pos, index = 0, el} = payload || {};
            const mcpInvoke = getState('mcpInvoke');
            const mcpItemPos = getMcpPos(pos, mcpInvoke.mcpList);
            const hitMcp = mcpItemPos && !mcpItemPos.isBlock();
            // 给微信标签加上extinfo
            const useMcp = hitMcp && mcpInvoke.globalConf?.wxOpen;

            try {
                // el为空的情况，直接返回
                if (!el || !el.style) {
                    return;
                }
                if (useMcp) {
                    el.setAttribute('extinfo', mcpItemPos.opt[index].scheme);
                    el.setAttribute('token', mcpItemPos.opt[index].token);
                    el.style.display = null;
                }
                else if (el && el.style) {
                    // mcp屏蔽后，不展示开放标签
                    el.style.display = 'none';
                }
            }
            catch (e) {
            }
        }
    },

    /**
     * @abstract led-wx-open-tag组件调用，开放标签点击
     */
    {
        eventName: 'mcp:wxOpenLaunchClick',
        handler: (payload: Record<string, any>) => {
            // 微信开放标签会回调两次
            const time = new Date().getTime();
            const {type, posKey} = payload;
            if (time - wxOpenLaunchClickTime < 500) {
                return;
            }
            wxOpenLaunchClickTime = time;

            if (type === 'click') {
                sendUbcClick({
                    value: '',
                    ext: {
                        pos: posKey,
                        isWxTag: 1,
                    },
                })
                return;
            }
        }
    },

    /**
     * @abstract led-wx-open-tag组件调用，开放标签点击
     */
    {
        eventName: 'mcp:wxOpenTagClick',
        handler: (payload: Record<string, any>, {getState, dispatch}: Store) => {
            // 微信开放标签会回调两次
            const time = new Date().getTime();
            const {type, posKey, index = 0} = payload;
            if (time - wxOpenTagTime < 500) {
                return;
            }
            wxOpenTagTime = time;
            // 点击后，特殊位置处理WXTag状态
            firstWxCkSolve({posKey}, {getState, dispatch});

            const {mcpList} = getState('mcpInvoke');
            const mcpItemPos = getMcpPos(posKey, mcpList);
            const token = mcpItemPos?.opt[index]?.token ?? '';
            const {isWeixin, isIOS} = uaInfo;

            if (isWeixin && !isIOS) {
                copyToClipBoard(token);
            }
            if (type === 'error') {
                /**
                 * 微信开放标签调起失败，走mcp调起
                */
                mcpMethod.invoke({
                    pos: posKey,
                    index
                });
                sendUbcClick({
                    value: 'wxopentag_click_error',
                });
            } else {
                mcpItemPos?.sendMcpLog?.('try_jump', index, {isWxTag: 1});
            }
        }
    }
];