/**
 * @file env
 * @param {ua} ua
 */

/**
 * isHuaweiBrowser 华为浏览器
 * isHuaweiOwnBrowser 华为自研浏览器(2018.10后的华为自带)
 * isWeixin
 * isIOS
 * isSafari
 * isOppoBrowser oppo浏览器
 * isAppInternal 分享页是否在端内打开
 * isQQ
 * isBaiduHi
 * isLite
 * isQQBrowser
 * isWeiBo
 * isBdHonor
 */
export const getUaInfo = (ua = window.navigator.userAgent) => ({
    isHuaweiBrowser: !!ua.match(/\(.*Android.*(HUAWEI|HONOR|HW-|H60-).*\)|^HONOR|^HUAWEI/i),
    isHuaweiOwnBrowser: !!ua.match(/HuaweiBrowser/i),
    isWeixin: ua.match(/micromessenger/gi),
    isIOS: ua.match(/iphone|ipod|ipad/i),
    isAndroid: ua.match(/android/i),
    isSafari: ua.match(/Safari/i),
    isOppoBrowser: !!ua.match(/oppobrowser|heytapbrowser/i),
    isAppInternal: / baiduboxapp\//i.test(ua),
    isQQ: ua.match(/QQ\//i), // qq
    isBaiduHi: ua.match(/baiduhi/gi),
    isLite: ua.match(/lite baiduboxapp|info baiduboxapp/gi),
    isQQBrowser: ua.match(/MQQBrowser/gi),
    isWeiBo: ua.match(/weibo/gi),
    isBdHonor: ua.match(/bdhonorbrowser/gi),
});

export const uaInfo = getUaInfo() || {};