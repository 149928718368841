/**
 * top guid store 
 */

export default {
    init() {
        return {
            buttonText: 'APP内打开',
            slogn: 'https://b.bdstatic.com/searchbox/icms/searchbox/img/baidu_slogan@2x.png',
        };
    },
};
