/**
 * @file app.ts
 */
import {defineComponent, Component as ComponentClass} from 'san';

/**
 * @param {SanComponent} Component san的 component
 * @param {string|DOM} selector attach 的节点
 * @param {boolean} append true 表示追加节点，否则是完全替换（先清空）
 */
const hulk = (
    Component: ComponentClass,
    selector: Element | string,
    {data = {}, append = true} = {}
) => {
    const doc = document;
    let app;
    if (Component instanceof ComponentClass) {
        app = Component;
    }
    else {
        const AppComponent = defineComponent(Component);
        app = new AppComponent({data});
    }

    let $node;
    switch (typeof selector) {
        case 'string':
            $node = doc.querySelector(selector);
            break;
        case 'undefined':
            $node = doc.body;
            break;
        default:
            if (selector.nodeType && selector.nodeName) {
                $node = selector;
            }
            else {
                throw new Error(selector + ' must be an DOM or DOM-selector!');
            }
    }

    if (!append && $node) {
        /* bca-disable */
        $node.innerHTML = '';
    }

    // @ts-ignore
    app.attach($node);
    return app;
};

export default hulk;
