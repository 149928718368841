/**
 * @file 管理各个位置调起/用于控制调起位展现状态
 * @abstract 控制展现show_xxx，控制交互逻辑ivk_xxx
 */
import {METHODS_POS} from "./request.mcp";

export const STATUS_POS_KEY = {
    /**
     * @abstract 全局
     * 2. show_dragButton 拖拽按钮
     * 3. show_globalMask 全局蒙层
     */
    [METHODS_POS.FUSION_DT_GLOBAL_BTN]: 'show_dragButton',
    [METHODS_POS.FUSION_DT_GLOBAL_MASK]: 'show_globalMask',

    /**
     * @abstract 顶部品牌导流
     * 1. show_topGuid 顶部品牌导流
     */
    [METHODS_POS.FUSION_DT_TOPGUID]: 'show_topGuid',

    /**
     * @abstract 融合播放
     * 1. show_playerPlayTag 展示播放按钮标签
     * 2. show_playerMount 展示播放区挂载卡
     */
    [METHODS_POS.FUSION_DT_PLAYERPLAY]: 'show_playerPlayTag',
    [METHODS_POS.FUSION_DT_PLAYERMOUNT]: 'show_playerMount',

    /**
     * @abstract 作者区域
     * 1. show_hotInfo 热榜
     * 2. show_readAllTag 展示展开全文标签
     */
    [METHODS_POS.FUSION_DT_HOTINFO]: 'show_hotInfo',
    [METHODS_POS.FUSION_DT_READALL]: 'show_readAllTag',

    /**
     * @abstract 推荐区域
     * 1. show_feedEnd 滑动到底
     * 2. show_feedMore 右侧更多内容/滑动到底
     * 3. show_feedBar 是否展示卡片互动bar/以它评论的调起状态判断
     * 4. ivk_feedlist 管理列表资源是否调起
     */
    [METHODS_POS.FUSION_DT_SHAREFEED_END]: 'show_feedEnd',
    [METHODS_POS.FUSION_DT_SHAREFEED_MORE]: 'show_feedMore',
    [METHODS_POS.FEED_NEWS_COMMENT]: 'show_feedBar',
    [METHODS_POS.SHARE_FEED_NEWS]: 'ivk_feedlist',
    [METHODS_POS.SHARE_FEED_VIDEOS]: 'ivk_feedlist',
};