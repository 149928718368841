/**
 * @file mcp utils
 */

// 获取mcpList是否有该pos调起配置
export function getMcpPos(pos: string, obj: Record<string, any>[]): any | boolean {
    let hasMcpPos = false;
    obj.map(item => {
        if (item.pos === pos) {
            hasMcpPos = item.invokeMcp;
            return hasMcpPos;
        }
    });
    return hasMcpPos;
};

// 存入ivkPosMap 前封装每个pos对象
export function getPosObj(mcpShare: Record<string, any>, pos: string) {
    let list = mcpShare && mcpShare.action_rule || {};
    let obj: Record<string, any> = {};
    for (let item in list) {
        if (item === pos) {
            obj = {
                pos: pos,
                opt: list[pos],
                logId: mcpShare.log_id
            };
        }
    }
    return obj;
};

// 获取所有pos位置
export function renderMcpList(
    mcpShare: Record<string, unknown>,
    mcpCreater: {
        create: (obj: Record<string, unknown>) => any;
    },
    extParam = {}
) {
    let actionRule = mcpShare && mcpShare.action_rule || {};
    let mcpList: any[] = [];
    for (let i in actionRule) {
        let mcpItemPos = mcpCreater.create({...getPosObj(mcpShare, i), extParam});
        mcpList.push({pos: i, invokeMcp: mcpItemPos});
    }
    return mcpList;
};