/**
 * @file 页面init准备
 */

import requestMcp from './request.module';
import shareFeed from './shareFeed.module';
import {sendUbcShow} from '../../../../lib/log';

class pageInit {
    constructor() {
    };

    init() {
        // 初始化开放标签
        requestMcp.reqWxopen();
        // 请求页面mcp接口
        requestMcp.reqPageBasic();        
        // 初始化推荐区/数据接口/推荐mcp接口
        shareFeed.init({});
        // 页面展现点位
        sendUbcShow({
            value: 'wholePage',
        });
    }
};

export default new pageInit();