/**
 * @file 融合页
 * @author chenglong13 <chenglong13@baidu.com>
 */
import './app.less';
import '@/lib/utils/patchSan';
import {Component} from 'san';
import {store} from '@/lib/Store';
import WxOpenTag from '@baidu/led-wx-open-tag';
import {MCP_POS_KEY} from '@/pages/fusion/utils';
import {sendUbcShow, sendUbcClick} from '@/lib/log';
import pageInit from '../core/modules/pageInit.module';
import mcpMethod from '../core/modules/mcpInvoke.module';
// 新融合动态
import FusionGlobalMask from '@baidu/led-ui-fusion-global-mask';
import FusionRecommend from '@baidu/led-ui-fusion-recommend';
import FusionTopGuid from '@baidu/led-ui-fusion-topguide';
import FusionPlayer from '@baidu/led-ui-fusion-player';
import FusionInfo from '@baidu/led-ui-fusion-info';
// 普通动态组件
import UIDynamicContent from '@baidu/led-ui-fusion-ugc-content';
import UIDynamicComment from '@baidu/led-ui-fusion-ugc-comment';

export default class AppComponent extends Component {
    static template = require('./app.html');

    static components = {
        'fusion-global-mask': FusionGlobalMask,
        'fusion-recommend': FusionRecommend,
        'fusion-top-guid': FusionTopGuid,
        'fusion-player': FusionPlayer,
        'fusion-info': FusionInfo,
        'wx-open-tag': WxOpenTag,
        'ui-dynamic-content': UIDynamicContent,
        'ui-dynamic-comment': UIDynamicComment,
    };

    static computed: Record<string, any> = {
        // 统一在这里调度，最终emit给组件的POS集合
        MCP_POS_KEY_EMIT() {
            const {isOldDynamic} = this.data.get('basicData') || {};
            let emitMcpKey = {...MCP_POS_KEY};

            // 旧动态统一处理POS
            if (isOldDynamic) {
                for(let key in emitMcpKey) {
                    switch (key) {
                        // 排除特殊的不作替换
                        case 'OldDT':
                        case 'ShareFeed':
                            break;
                        // 旧动态的mcpKey换成OldDT.default
                        default:
                            for (let key2 in emitMcpKey[key]) {
                                emitMcpKey[key][key2] = MCP_POS_KEY.OldDT.default;
                            }
                    }
                }
            }

            store.dispatch('mcp:updatePosKey', {
                posKey: emitMcpKey,
            });

            return emitMcpKey;
        },
    };

    inited() {
        // 页面初始化
        pageInit.init();
    };

    initData() {
        return {
            MCP_POS_KEY_EMIT: {},
            // 向组件中注入的API
            pageApi: {
                sendLog: {
                    show: sendUbcShow,
                    click: sendUbcClick,
                },
                mcpInvokeApp: mcpMethod.invoke,
            },
        };
    };
}
