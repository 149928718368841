/**
 * @file mcp action_flag状态管理
 * @abstract 需要控制调起是否展现的位置，默认初始化为true
 */

export default {
    init() {
        return {
            /**
             * @abstract 全局
             * 1. show_globalMask 全局蒙层是否展示
             * 2. show_dragButton 拖拽按钮是否展示
             */
            show_globalMask: true,
            show_dragButton: true,

            /**
             * @abstract 顶部品牌条
             * 1. show_dragButton 拖拽按钮是否展示
             */
            show_topGuid: true,

            /**
             * @abstract 融合播放
             * 1. show_playerPlayTag 播放按钮wx标签是否展示
             * 2. show_playerMount 播放器上常规挂载卡是否展示
             */
            show_playerPlayTag: true,
            show_playerMount: true,

            /**
             * @abstract 作者互动区
             * 1. show_hotInfo 作者区热榜是否展示
             * 2. show_readAllTag 查看全文wx标签
             */
            show_hotInfo: true,
            show_readAllTag: true,

            /**
             * @abstract 推荐区域
             * 1. show_feedEnd 滑动到底是否展示
             * 2. show_feedMore 右侧更多推荐展现
             * 3. show_feedBar 卡片互动bar展现
             * 4. ivk_feedlist 卡片调起状态
             */
            show_feedEnd: true,
            show_feedMore: true,
            show_feedBar: true,
            ivk_feedlist: true,
        };
    },
};