/**
 * @file 公用函数
 */
export * from './env';
export * from './mcp';

/**
 * @abstraction 获取url参数
 */
export const getQueries = () => {
    const url = location.search;
    let result: JSONValue = {};

    if (url.indexOf('?') >= 0) {
        let str = url.substr(1);
        let queries = str.split('&');

        queries.forEach(query => {
            let name = query.split('=')[0];
            let value = unescape(query.split('=')[1]);
            result[name] = value;
        });
    }

    return result;
};

/**
 * @abstraction 防抖
 */
export const debounce = function (fn: Function, delay: number) {
    let timer: any = null;
    return function (...args: any[]) {
        // @ts-ignore
        let that = this;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(that, args);
        }, delay);
    };
};

/**
 * @abstraction 滚动到底部
 */
export const scroll = (fn: Function, delay: number, main = window) => {
    // 当父元素高度设置为100vh时，将滚动事件绑定到父元素main上
    const scrollElement = main || window;
    let doc = document;
    let docEle = doc.documentElement;
    let body = doc.body;
    scrollElement.addEventListener('scroll', debounce(() => {
        if (docEle.clientHeight + (docEle.scrollTop || body.scrollTop) + 100 >= body.clientHeight) {
            fn();
        }
    }, delay));
};

/**
 * @abstraction get cookie
 */
export const cookie = function (key: string, value?: string, options?: any) {
    let milliseconds;
    let time;
    let result;
    let decode;
    if (arguments.length === 0) {
        return document.cookie;
    }
    // A key and value were given. Set cookie.
    if (arguments.length > 1 && String(value) !== '[object Object]') {
        // Enforce object
        options = options || {};
        if (value === null || value === undefined) {
            options.expires = -1;
        }
        if (typeof options.expires === 'number') {
            milliseconds = options.expires;
            time = options.expires = new Date();
            time.setTime(time.getTime() + milliseconds);
        }
        value = String(value);
        return (document.cookie = [
            key,
            '=',
            options.raw ? value : encodeURIComponent(value),
            options.expires ? '; expires=' + options.expires.toUTCString() : '',
            options.path ? '; path=' + options.path : '',
            options.domain ? '; domain=' + options.domain : '',
            options.secure ? '; secure' : '',
        ].join(''));
    }
    // Key and possibly options given, get cookie
    options = value || {};
    // @ts-ignore
    decode = options.raw ? function (s) {
        return s;
    } : decodeURIComponent;
    return (result = new RegExp('(?:^|; )'
        + key + '=([^;]*)').exec(document.cookie)) ? decode(result[1]) : null;
};

/**
 * @abstract 复制粘贴板
 */
export const copyToClipBoard = (text: string) => {
    let tag = document.createElement('input');
    tag.setAttribute('readonly', '');
    tag.value = text;
    document.body.appendChild(tag);

    tag.select();
    tag.setSelectionRange(0, tag.value.length);

    tag.removeAttribute('readonly');

    try {
        document.execCommand('copy');
        window.console.warn('复制口令clipboard返回值：', text);
    }
    catch (error) {}

    try {
        tag.blur();
    }
    catch (error) {}

    tag.remove();
};