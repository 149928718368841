/**
 * @file 推荐流事件改造
 */
import {store} from '@/lib/Store';
import {
    getQueries,
    scroll,
} from '@/lib/utils';
import {REQUEST_FEED_POS} from '@/pages/fusion/utils';
import {getShareFeedData} from "@/pages/fusion/service";
import requestMcp from './request.module';

// todo：推荐区拆mcp请求参数，待优化
const getRelateMcpParams = (recoList: any[]) => {
    const sv: any[] = [];
    const dt: any[] = [];
    const news: any[] = [];
    const newsComment: any[] = [];

    const {scene_id = '', _refluxos = ''} = getQueries();

    if (recoList && recoList.length > 0) {
        recoList.forEach(item => {
            if (item.type === 'news') {
                const data = {
                    nid: item.id,
                    topic_id: item.data.thread_id,
                    opentype: item.data.thread_id ? 2 : 1,
                    uk: '',
                    scene_id,
                    _refluxos,
                };

                const myIndex = news.push(data);
                newsComment.push(data);
                item.posIndex = myIndex - 1;
            }
            else if (item.type === 'sv') {
                const videoInfo = item.data.news_share_sv_params.videoInfo;
                const videoCropPos = encodeURIComponent(JSON.stringify([]));
                const videoData = {
                    duration: videoInfo.freeDuration ? videoInfo.freeDuration : videoInfo.duration,
                    nid: videoInfo.nid,
                    nid_src: videoInfo.ext_log.nid_src,
                    'text_rect': videoCropPos,
                    resourceType: videoInfo.ext_log.resourceType,
                    vid: videoInfo.vid,
                    videoHeight: videoInfo.ext.videoHeight,
                    videoWidth: videoInfo.ext.videoWidth,
                    title: encodeURIComponent(videoInfo.title),
                    videoUrl: encodeURIComponent(videoInfo.videoUrl),
                    pageUrl: encodeURIComponent(videoInfo.pageUrl),
                    posterImage: encodeURIComponent(videoInfo.posterImage),
                    scene_id,
                    _refluxos,
                };
                const myIndex = sv.push(videoData);
                item.posIndex = myIndex - 1;
            }
            else if (item.type === 'dt') {
                const myIndex = dt.push({
                    nid: item.id,
                    topic_id: item.data.thread_id,
                    opentype: item.data.thread_id ? 2 : 1,
                    scene_id,
                    _refluxos,
                });
                item.posIndex = myIndex - 1;
            }
        });
    }
    return {
        recoList,
        posData: {
            [REQUEST_FEED_POS.SHARE_FEED_NEWS]: news.length ? news : [{}],
            [REQUEST_FEED_POS.SHARE_FEED_VIDEOS]: sv.length ? sv : [{}],
            [REQUEST_FEED_POS.SHARE_FEED_DYNAMICS]: dt.length ? dt : [{}],
            [REQUEST_FEED_POS.FEED_NEWS_COMMENT]: newsComment.length ? newsComment : [{}],
        },
    };
};

/**
 * @abstract 推荐区核心事件
 */
class shareFeed {
    constructor() {};

    init(payload: Record<string, any>) {
        const main = payload?.main;

        // 请求推荐区
        getShareFeedData().then((res: any) => {
            const pageInfo = res.data.pageInfo || {};
            const shareFeed = pageInfo.shareFeed || {};
            const data = getRelateMcpParams(shareFeed.news || []);

            // 更新mcp pos
            if (data.recoList.length === 0) {
                store.dispatch('shareFeed:updataRecoStatus', false);
            }
            store.dispatch('shareFeed:updataRecoList', data.recoList.splice(0, 3));
            store.dispatch('shareFeed:updataRecoPosData', data.posData);

            // 请求mcp请求获取pos
            requestMcp.reqShareFeed();

            // 假分页
            scroll(() => {
                const newFeed = data.recoList.splice(0, 10);
                if (newFeed.length) {
                    const oldFeed = store.getState('shareFeed.list');
                    store.dispatch('shareFeed:updataRecoList', [
                        ...oldFeed,
                        ...newFeed,
                    ]);
                }
                if (!data.recoList.length) {
                    store.dispatch('shareFeed:updataRecoStatus', false);
                }
            }, 200, main);
        });
    }
};

export default new shareFeed();
