/**
 * @file all actions update store集合
 */

import mcpCommon from '../core/actions/common.mcp';
import builderMcp from '../core/actions/mcp.action';
import buildShareFeed from '../core/actions/shareFeed.action';

export default {
    ...[
        ...mcpCommon,
        ...builderMcp,
        ...buildShareFeed,
    ],
};
