/**
 * @file 组件映射posKey map
 * 主要用于一个组件拆分多个调起位，勿直接在组件写死poskey
 */
import {METHODS_POS} from "./request.mcp";

export const MCP_POS_KEY: {[key: string]: any}= {
    /**
     * 扩展全局
     * 1. drag 拖拽按钮
     * 2. mask 全局蒙层
     */
    Global: {
        drag: METHODS_POS.FUSION_DT_GLOBAL_BTN,
        mask: METHODS_POS.FUSION_DT_GLOBAL_MASK,
    },

    /**
     * 品牌导流
     */
    TopGuid: {
        default: METHODS_POS.FUSION_DT_TOPGUID,
    },

    /**
     * 融合播放器
     * 1. default 默认通用
     * 2. playend 完播卡
     * 3. play 播放按钮
     */
    FusionPlayer: {
        default: METHODS_POS.FUSION_DT_PLAYER,
        playend: METHODS_POS.FUSION_DT_PLAYEREND,
        play: METHODS_POS.FUSION_DT_PLAYERPLAY,
        mount: METHODS_POS.FUSION_DT_PLAYERMOUNT,
    },

    /**
     * 作者信息区
     * 1. default 默认通用调起的位置
     * 2. interact 底部互动区
     * 3. head 作者head区
     * 4. readAll 全文点击
     * 5. hotInfo 热榜条
     */
    AuthorInfo: {
        default: METHODS_POS.FUSION_DT_AUTHORINFO,
        interact: METHODS_POS.FUSION_DT_AUTHORINFO,
        head: METHODS_POS.FUSION_DT_AUTHORINFO,
        readAll: METHODS_POS.FUSION_DT_READALL,
        hotInfo: METHODS_POS.FUSION_DT_HOTINFO,
    },

    /**
     * 推荐区
     * 1. news 图文卡片
     * 2. sv 视频卡片
     * 3. dt 动态卡片
     * 4. end 滑动到底
     * 5. more 副标题查看更多
     * 6. newsCom 图文卡片-评论
     */
    ShareFeed: {
        news: METHODS_POS.SHARE_FEED_NEWS,
        sv: METHODS_POS.SHARE_FEED_VIDEOS,
        dt: METHODS_POS.SHARE_FEED_DYNAMICS,
        newsCom: METHODS_POS.FEED_NEWS_COMMENT,
        end: METHODS_POS.FUSION_DT_SHAREFEED_END,
        more: METHODS_POS.FUSION_DT_SHAREFEED_MORE,
    },

    /**
     * @abstract 旧动态卡片，旧动态指上文下图形式的
     */
    OldDT: {
        default: METHODS_POS.FUSION_OLDDT_DEFAULT,
        content: METHODS_POS.FUSION_OLDDT_DEFAULT,
        comment: METHODS_POS.FUSION_OLDDT_DEFAULT,
    },
};