
/**
 * @file 端外融合页
 * @abstract 预期也可承载图文/视频
 * @abstract 特殊情况以xxfusion命名，如newsfusion、videfusion
 * @abstract 融合公共部分组件，在src/component包装，统一输出给所有融合页面使用
 * @author chenglong13
 */

import app from '@/lib/App';
import {connect} from '@/lib/Store';
import AppComponent from './containers/app';
import {initStore} from './store';

const keyZipObject = (keyList: any) => {
    const result: Record<string, any> = {};
    keyList.forEach((v: any) => (result[v] = v));
    return result;
};

const initPage = (sortsData: any) => {
    const store = initStore(sortsData);

    const keyMap = keyZipObject(Object.keys(store.raw));

    const APPComponent2Store = connect.san(keyMap)(AppComponent);

    const render = () => {
        app(APPComponent2Store, '#app', {
            data: sortsData,
            append: false,
        });
    };

    const degradeRender = () => {
        render();
    };

    degradeRender();
};

initPage(window.jsonData || {});
