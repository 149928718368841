/**
 * @file REQUEST_POS这里仅用来请求//调起请使用集合的 METHODS_POS
 */

/**
 * @abstract 新融合pos
 * DT 标识动态
 */
export const REQUEST_POS = {
    DT: {
        /**
         * 全局
         * 1. pos_fusion_dt_globalMask 全局调起
         * 2. pos_fusion_dt_globalBtn 全局拖拽按钮
         */
        FUSION_DT_GLOBAL_MASK: 'pos_fusion_dt_globalMask',
        FUSION_DT_GLOBAL_BTN: 'pos_fusion_dt_globalBtn',

        /**
         * 顶部品牌bar区域
         * 1. pos_fusion_dt_topguid 顶bar
         */
        FUSION_DT_TOPGUID: 'pos_fusion_dt_topguid',

        /**
         * 新融合区域
         * 1. pos_fusion_dt_player 融合播放器整体
         * 2. pos_fusion_dt_playerEnd 播放结束，完播卡
         * 3. pos_fusion_dt_playerPlay 播放按钮
         * 4. pos_fusion_dt_playerMount 挂载卡
         */
        FUSION_DT_PLAYER: 'pos_fusion_dt_player',
        FUSION_DT_PLAYEREND: 'pos_fusion_dt_playerEnd',
        FUSION_DT_PLAYERPLAY: 'pos_fusion_dt_playerPlay',
        FUSION_DT_PLAYERMOUNT: 'pos_fusion_dt_playerMount',

        /**
         * 作者互动区
         * 1. pos_fusion_dt_authorInfo 作者互动区公共
         * 2. pos_fusion_dt_hotInfo 热榜条
         * 3. pos_fusion_dt_readAll 查看全文
         */
        FUSION_DT_AUTHORINFO: 'pos_fusion_dt_authorInfo',
        FUSION_DT_HOTINFO: 'pos_fusion_dt_hotInfo',
        FUSION_DT_READALL: 'pos_fusion_dt_readAll',

        /**
         * 推荐区/单个调起位
         * 1. pos_fusion_dt_sharefeedEnd 底部滑动到尽头/主feed
         * 2. pos_fusion_dt_sharefeedMore 右侧更多精彩内容/主feed
         */
        FUSION_DT_SHAREFEED_END: 'pos_fusion_dt_sharefeedEnd',
        FUSION_DT_SHAREFEED_MORE: 'pos_fusion_dt_sharefeedMore',

        /**
         * 旧动态区域
         * 1. pos_dt_drag_button 旧动态默认调起，复用拖拽按钮
         */
        FUSION_OLDDT_DEFAULT: 'pos_dt_drag_button',
    },
};

/**
 * @abstract 推荐区pos
 */
export const REQUEST_FEED_POS = {
    // 1. 精彩推荐：图文
    SHARE_FEED_NEWS: 'pos_fusion_sharefeed_news',
    // 2. 精彩推荐：视频
    SHARE_FEED_VIDEOS: 'pos_fusion_sharefeed_video',
    // 3. 精彩推荐：动态卡--目前server不分发
    SHARE_FEED_DYNAMICS: 'pos_fusion_sharefeed_dt',
    // 4. 精彩推荐：图文--推荐区评论
    FEED_NEWS_COMMENT: 'pos_fusion_sharefeed_comment',
};

/**
 * @abstract 统一输出
 */
export const METHODS_POS: Record<string, any> = {
    // 这里集合推荐区的POS
    ...REQUEST_FEED_POS,

    // 集合常规区域的POS
    ...REQUEST_POS.DT,
};