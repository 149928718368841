/**
 * mcp 初始化
 */
import {IvkAppCreator} from '@baidu/wise-invoke-app';
import {getQueries, uaInfo, cookie} from '@/lib/utils';
import {envConf, LOG_PAGE} from '@/pages/fusion/utils';

const {scene, wxTagAppid, openWxOpenTag} = envConf;

const {
    nid,
    ruk,
    // 互动-短链服务携带
    sid_for_share: appSid,
    feed_sid: feedUrlSid,
    sourceFrom
} = getQueries();

export default {
    init({data}:  Record<string, any>) {
        // 生成mcp构造器
        const mcpCreater = new IvkAppCreator();

        /* eslint-disable */
        const feedSid = data?.feed_sid ?? '';
        const sid = feedUrlSid ? feedUrlSid : feedSid;

        /**
         * 设置MCP请求的公共参数
         * @param app wise 固定
         * @param scene sharepage 主版分享场景
         * @param ruk 关系链作者uk
         * @param nid 文章nid
         * @param sid feed实验sid
         * @param app_sid 端实验携带的sid
         * @param page 页面标识
         */
        const params = {
            // 必传参数
            app: 'wise',
            scene: scene || 'sharepage',
            // ruk/sid/nid
            page: LOG_PAGE,
            ruk: ruk || '',
            nid: nid || '',
            sid: sid || '',
            app_sid: appSid || '',
            // 如下随业务迭代新抽出的参数
            source: sourceFrom || '',
            baiduid: cookie('BAIDUID'),
            user_agent: navigator.userAgent,
            is_weixin: uaInfo.isWeixin ? 1 : 0,
            share_url: location.href.split('#')[0],
            shareParams: {
                page: LOG_PAGE,
                sourceFrom: sourceFrom || '',
            },
            // 需要统一在这定义，不建议在请求的地方加
            ext_sid: '',
        };
        mcpCreater.setCommonParams(params);
        /* eslint-disable */

        return {
            /**
             * @param globalConf 开放标签验签的配置
             * @param isShowWxOpenTag 初始化是否展示微信标签，返回globalConf后更新
             * @param mcpCreater MCP构造器，没弄明白来源的方法，在这里 @baidu/wise-inovke-app
             * @param mcpList MCP所有POS返回集合
             */
            sid,
            env: {},
            mcpCreater,
            mcpList: [],
            posKey: [],
            globalConf: {},
            isShowWxOpenTag: openWxOpenTag,
            wxTagAppidNow: wxTagAppid,
        };
    },
};
