/**
 * @file store 数据初始化
 */
import stores from './stores';

/**
 * @param data {Object} 传入的标准数据new.d.ts结构
 * @param urlQuery {Object} url参数
 * @return 初始化的StoreNews对象数据
 */
const processInitData = (data = {}, urlQuery = {}) => {
    const finalData: Record<string, any> = {};

    // eslint-disable-next-line guard-for-in, no-unused-vars
    for (const key in stores) {
        if (typeof (stores as Stores)[key].init === 'function') {
            finalData[key] = (stores as Stores)[key].init(data, urlQuery);
        }
        else {
            finalData[key] = (stores as Stores)[key];
        }
    }
    return finalData;
};

export default processInitData;