/**
 * store集合
 */

// basic 业务 store
import basicData from '../core/stores/basic/basicData.store';
import topGuid from '../core/stores/basic/topGuid.store';
import fusionPlayer from '../core/stores/basic/fusionPlayer.store';
import shareFeed from '../core/stores/basic/shareFeed.store';
import authorInfo from '../core/stores/basic/authorInfo.store';
import dragButton from '../core/stores/basic/dragButton.store';

// mcp 公共 store
import mcpInvoke from '../core/stores/mcp/mcpInvoke.store';
import ivkFlag from '../core/stores/mcp/ivkFlag.store';

export default {
    basicData,
    topGuid,
    shareFeed,
    authorInfo,
    dragButton,
    fusionPlayer,
    ivkFlag,
    mcpInvoke,
};
