/**
 * 拖拽按钮
 */

import {envConf} from '@/pages/fusion/utils';

export default {
    init() {
        const appTitle = envConf.appTitle || '百度APP';
        return {
            title: appTitle + '内播放',
            logo: envConf.appLogo,
            background: 'linear-gradient(-11deg, #957BFF 10.19%, #4E6EF2 89.34%)',
            boxShadow: '0px 5px 25px 0px rgba(78, 110, 242, 0.40)',
        };
    },
};
