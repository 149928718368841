/**
 * @file 将 storeDispatch 放到 Component 原型上
 */
import {store} from '@/lib/Store';
import {Component} from 'san';

// Component 上的 dispatch 指示为 store 的 dispatch
(Component as Record<string, any>).prototype.storeDispatch = store.dispatch.bind(store);
// 删除空白节点
(Component as Record<string, any>).prototype.trimWhitespace = 'all';
