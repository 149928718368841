/**
 * @file san store 类的基本封装
 */

import {
    connect as sanConnect,
    Store,
} from 'san-store';
const createConnector = sanConnect.createConnector;

class NewStore extends Store {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(options: any) {
        super(options);
    }
    /**
     * 初始化数据
     * @param {Object} data 初始化数据
     */
    initData(data: any) {
        // 这里有风险，如果 store 类的实现变了名称，会影响
        // 升级注意！
        if (typeof data === 'object') {
            const raw = this.raw;

            Object.keys(data).forEach(name => {
                if (raw[name]) {
                    throw new Error('Store.initData: ' + name + ' exists!');
                }
                else {
                    // console.log(name)
                    raw[name] = data[name];
                }
            });
        }
        return this;
    }
    /**
     * 批量添加action
     * @param  {Object} actions 添加的action数据，key：Function
     */
    addActions(actions: {[key: string]: any}) {
        const addAction = this.addAction.bind(this);
        if (typeof actions === 'object') {
            Object.keys(actions).forEach(name => {
                if (typeof actions[name] === 'function') {
                    addAction(name, actions[name]);
                    return;
                }

                const {eventName, handler} = actions[name];
                if (typeof handler === 'function') {
                    addAction(eventName, handler);
                }
            });
        }
        else {
            throw new Error('Store.addActions: actions type should be an object');
        }
        return this;
    }
}

/**
 * 默认的全局 Store 实例
 * 通常我们认为在一个应用应该具有一个全局唯一的 store，管理整个应用状态
 *
 * @type {Store}
 */
export let store = new NewStore({
    name: '__default__',
});

export {Store};

export let connect = {
    san: createConnector(store),
    createConnector,
};
