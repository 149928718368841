/**
 * @file spy日志
 */
import {uaInfo} from './env';

const pid = '10_75';
const sampleRate = 1;
// 判断SP是否引入
export const hasSP = window.SP && window.SP.mark;
// 拓展window.SP的函数
export const extendSP = {

    /**
     * 发送spy日志
     */
    sendSpy() {
        if (!(window.SP && window.SP.mark)) {
            return;
        }
        const SP = window.SP;
        let config = SP.config || {};
        let {startTime, marks = {}, starts, events = {}, server = {}} = SP.data || {};
        let info: Record<string, any> = {};
        // 收集自定义打点信息
        Object.keys(events).forEach(name => {
            info[name] = events[name];
        });
        // 收集mark打点时间戳
        Object.keys(marks).forEach(name => {
            let sTime = starts[name] || startTime;
            info[name] = marks[name] - sTime;
        });
        // 收集后端打点
        Object.keys(server).forEach(key => {
            info[key] = server[key];
        });
        // 将计算出的spy日志放到window里以方便数据分析
        window._spyInfo = info;
        // 发送
        const domain = location.protocol === 'http:'
            ? 'http://sestat.baidu.com/mwb2.gif?'
            : 'https://sp1.baidu.com/5b1ZeDe5KgQFm2e88IuM_a/mwb2.gif?';
        // 构造url的query部分
        const query = [
            'pid=' + config.pid, // 必须
            'ts=' + Date.now(), // 必须，页面的logid
            'type=perf', // 必须，perf 说明是性能日志， except异常日志等
            'group=' + config.group, // 可选，group分组默认是common
            // 必须，性能指标采集
            'info=' + encodeURIComponent(
                JSON.stringify(info)
            ),
            'dim=' + encodeURIComponent(
                JSON.stringify(config.dim)
            )
        ].join('&');
        const url = domain + query;
        (new Image()).src = url;
    },

    /**
     * 重置sdk配置
     * @param {Object} conf sdk的配置
     */
    resetConfig(conf: Record<string, unknown> = {}) {
        if (!(window.SP && window.SP.mark)) {
            return;
        }
        const SP = window.SP;
        const dataKey = [
            'startTime' // 日志数据起始点
        ];
        const confKey = [
            'pid', // 必传, spy的pid
            'group', // spy的分组默认有两种分组：h5/webview
            'pname', // ubcserver日志的page取值, sdk维护
            'dim' // 维度
        ];
        Object.keys(conf).forEach(key => {
            if (confKey.indexOf(key) >= 0 && SP.config) {
                SP.config[key] = conf[key];
            }
            if (dataKey.indexOf(key) >= 0 && SP.config) {
                SP.data[key] = conf[key];
            }
        });
    }
};

export const getDim = () => {
    return {
        os: uaInfo.isAndroid ? 'Android' : uaInfo.isIOS ? 'iOS' : 'PC'
    };
};

// 双端都会发送server的日志打点
export const sendSpyLog = (group = '') => {
    if (!(window.SP && window.SP.mark)) {
        return;
    }
    // 起始时间点startTime取值优先级：performance.timing.navigationStart > head 头部开始加载时间戳
    let SP = Object.assign(window.SP, extendSP);
    let startTime = SP.data && SP.data.headTime;
    let performance = window.performance;
    if (performance && performance.timing) {
        /* istanbul ignore next */
        startTime = performance.timing.navigationStart;
    }
    let isSend = Math.random() < sampleRate;
    // 重置一些配置
    SP.resetConfig({
        pid: pid,
        startTime,
        group,
        dim: getDim()
    });
    // 发送日志
    isSend && SP.sendSpy();
};

const errorStackCallbackArrays: any[] = [];
let isSpyInit = false;

/**
 * appendScript
 * 在Body标签后面插入一段js脚本
 * @param params script标签的变量
 * @example
 * appendScript({src: 'http://xxx.js'})
 */
export const appendScript = (params: Record<string, any>) => {
    if (!params) {
        return;
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    // eslint-disable-next-line no-unused-vars
    for (let i in params) {
        if (params.hasOwnProperty(i)) {
            // @ts-ignore
            script[i] = params[i];
        }
    }
    document.body.appendChild(script);
};

/**
 * errorLog
 * spy错误监控，调用后若spy没有init，会等待其init后一起调用
 * @param e 错误
 * @param params spy参数
 */
export const errorLog = (e: Event, params: Record<string, unknown>) => {
    const baseParams = {
        pid: pid,
        dim: getDim()
    };
    params = Object.assign({}, params, baseParams);

    if (!isSpyInit) {
        errorStackCallbackArrays.push([e, params]);
        return;
    }
    /* istanbul ignore next */
    window.__spyClinet && window.__spyClinet.sendExceptForError(e, params);
};

/**
 * initSpy
 * 初始化spy脚本，目前主要用于错误监控
 */
export const initSpy = () => {
    appendScript({
        src: 'https://b.bdstatic.com/searchbox/icms/searchbox/js/spy-client@2.0.3/spy-client-basic.min.js',
        onload: () => {
            // eslint-disable-next-line no-undef
            /* istanbul ignore next */
            // @ts-ignore
            window.__spyClinet = new SpyClient({
                pid: pid,
                sample: 0.1
            });
            /* istanbul ignore next */
            isSpyInit = true;
            /* istanbul ignore next */
            errorStackCallbackArrays.forEach(([e, params]) => {
                errorLog(e, params);
            });
        }
    });
};

/**
 * spLogMark
 * 记录时间点，若key值没有对应的start声明，则会以页面开始加载的时间为起始值做计算
 * @param key 性能key值
 */
export const spLogMark = (key: string) => {
    hasSP && window.SP.mark(key);
};

/**
 * spLogStart
 * 性能key对应的开始时间点
 * @param key 性能key值
 */
export const spLogStart = (key: string) => {
    hasSP && window.SP.start(key);
};

/**
 * 错误日志
 * @param {*} info error参数
 * @param {*} group 日志group 默认为js日志
 */
export const spyError = ({info, group = ''}: Record<string, any>) => {
    setTimeout(() => {
        window.__spyHead && window.__spyHead.send({
            group,
            dim: getDim(),
            info: {
                url: window.location.href,
                ...info
            }
        });
    }, 0);
};