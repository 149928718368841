/**
 * @file ubc打点
 */

import BaseLog from '@baidu/feed-baselog';
import {getQueries} from '../utils/index';
import * as wlogP from '@baidu/wise-log-params';
import {LOG_PAGE} from '../../pages/fusion/utils';

const {
    from: wlogFrom,
    source: wlogSource
} = wlogP.getUrlParams(location.href);
// appSid，端内实验，互动-短链服务携带
const {sid_for_share: appSid, nid, ruk}: any = getQueries() || {};
// feedSid，Feed实验，Feed Server
export const feedSid = window?.jsonData?.data?.feed_sid || '';
// baseLog
const baseLog = new BaseLog();

/**
 * @abstract 默认参数/可扩展场景
 */
export const getLogParam = () => {
    return {
        id: 17292,
        page: LOG_PAGE,
        source: wlogSource,
        from: wlogFrom,
        ext: {
            sid: feedSid,
            appSid,
            nid,
            ruk,
            uaType: wlogP.getUAInfo(navigator.userAgent),
            os: wlogP.getUAInfo(navigator.userAgent, 'os'),
            host: location.host,
        },
    };
};

/**
 * @abstract 发送ubc日志
 */
export const sendUbc = (opt: any) => {
    const logParam = getLogParam();

    const params = {
        type: opt.type,
        value: opt.value,
        from: opt.from || logParam.from,
        source: opt.source || logParam.source,
        page: opt.page || logParam.page,
        ext: Object.assign({}, opt.ext, logParam.ext),
    };

    baseLog.sendUbc({
        ubcId: opt.id || logParam.id,
        params,
        // 点位归到baiduboxapp产品线，过签章
        queryParams: {
            appname: 'baiduboxapp',
        },
    });
};

/**
 * @abstract 展现日志
 */
export const sendUbcShow = (opt: {value: string, ext?: JSONValue}) => {
    sendUbc({
        type: 'show',
        value: opt.value,
        ext: opt.ext,
    })
};

/**
 * @abstract 点击日志
 */
export const sendUbcClick = (opt: {value: string, ext?: JSONValue}) => {
    sendUbc({
        type: 'click',
        value: opt.value,
        ext: opt.ext,
    })
};

/**
 * @abstract 发送监控报警日志
 */
export const sendCompasslog = (opt: {
    errorType: string,
    value: string,
    msg?: string,
    stack?: string,
    errno?: string | number,
}) => {
    sendUbc({
        id: 16130,
        from: 'feed',
        type: opt.errorType,
        value: opt.value,
        ext: {
            msg: opt.msg || '',
            stack: opt.stack || '',
            error: opt.errno || '',
        }
    })
};