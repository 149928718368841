/**
 * basic action builder
 * @abstract eventName以{ 组件action名(见action/index):updatexxx }
 */

import {builder} from 'san-update';

export default [
    /**
     * @abstract 推荐区builder
     */
    {
        eventName: 'shareFeed:updataRecoList',
        handler: (payload: Payload) => {
            return builder().set('shareFeed.list', payload);
        }
    },
    {
        eventName: 'shareFeed:updataRecoStatus',
        handler: (payload: Payload) => {
            return builder().set('shareFeed.loading', payload);
        }
    },
    {
        eventName: 'shareFeed:updataRecoPosData',
        handler: (payload: Payload) => {
            return builder().set('shareFeed.posData', payload);
        }
    },
]