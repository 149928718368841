/**
 * @file request mcp事件
 */
import {store} from '@/lib/Store';
import {
    renderMcpList,
    getMcpPos
} from '@/lib/utils';
import {
    REQUEST_POS,
    STATUS_POS_KEY,
} from '@/pages/fusion/utils';
import wxInit from './wxopen.module';
import dragButton from './dragButton.module';
import mcpMethod from './mcpInvoke.module';

/**
 * @abstract 调整透传给mcp的变量
 * 整理：https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/1ab8uT5A9J/M4xPFcXvdA/x2hS5LNyNyrP84
 */
const mcpBasicParams = (state: Record<string, any>) => {
    const invokeInfo: Record<string, any> = {};
    const mcpPosMap: Record<string, any> = REQUEST_POS.DT;
    const mcpPos: any[] = [];
    // 将map value集合为数组
    for (let key in mcpPosMap) {
        mcpPos.push(mcpPosMap[key]);
    }
    // RD同步数据
    const {comment, shareHotInfo} = state?.authorInfo || {};
    const {feedSid, nid} = state?.basicData || {};

    /**
     * @param source 来源
     * @param topic_id 评论id
     * @param titleExpand 是否展开NA全文
     * @param toComment 是否展开NA评论
     */
    const basicReqParam = [
        {
            vid: nid || '',
            nid: nid || '',
            feed_id: feedSid || '',
            topic_id: comment?.threadId || '',
            source: 'fusion-dt-page',
            titleExpand: '0',
            toComment: '0',
        },
    ];

    // 遍历POS加上透传给MCP的参数
    mcpPos.forEach(pos => {
        const obj = [];
        switch (pos) {
            // 热榜POS特殊处理
            case mcpPosMap.FUSION_DT_HOTINFO:
                obj.push({
                    bizparams: encodeURIComponent(JSON.stringify(shareHotInfo?.bizparams || {
                        routerInfo: {
                            url: '/hotlist',
                        },
                        source: 'external_share_video',
                        tab_id: '10076',
                    })),
                });
                break;
            default:
                obj.push(...basicReqParam);
        }

        invokeInfo[pos] = obj;
    });
    return invokeInfo;
};

class requestMcp {
    constructor() {
    };

    /**
     * @abstract 初始化微信开放标签mcp请求
     */
    reqWxopen() {
        const mcpInvoke = store.getState('mcpInvoke');
        // 获取mcp构造器
        const mcpCreater = mcpInvoke.mcpCreater || '';
        if (mcpCreater) {
            mcpCreater.getInvokeInfo({}).then((res: any) => {
                const globalConf = res.global_conf || {};

                // 设置全局的globalConf
                store.dispatch('mcp:setGlobalConf', {globalConf});

                if (globalConf && globalConf.wxOpen) {
                    // 初始化wx sdk
                    wxInit.initWxopen(globalConf);
                    // 更新 isShowWxOpenTag 状态
                    store.dispatch('mcp:updateShowWxTagFlag', {
                        wxOpen: globalConf.wxOpen,
                    });
                }
            });
        }
    };

    /**
     * @abstract 请求推荐区mcp接口
     */
    reqShareFeed() {
        const mcpInvoke = store.getState('mcpInvoke');

        // 取到推荐区所有的pos
        const params = store.getState('shareFeed.posData');

        // 获取mcp构造器
        const mcpCreater = mcpInvoke.mcpCreater || '';

        if (mcpCreater) {
            const mcpParams = {
                invoke_info: params,
            };

            mcpCreater.getInvokeInfo(mcpParams).then((res: any) => {
                try {
                    store.dispatch('mcp:updateMcpList', renderMcpList(res, mcpCreater));
                    // 更新mcp是否调起状态
                    this.getActionFlag();
                }
                catch (e) {}
            })
        }
    };

    /**
     * @abstract 业务基础的mcp请求
     */
    reqPageBasic () {
        const mcpInvoke = store.getState('mcpInvoke');
        // 获取mcp构造器
        const mcpCreater = mcpInvoke.mcpCreater || '';

        // 获取请求mcp参数
        const params = mcpBasicParams(store.getState());

        if (mcpCreater) {
            const mcpParams = {
                invoke_info: params,
            };
            mcpCreater.getInvokeInfo(mcpParams).then((res: any) => {
                try {
                    // 更新MCP返回集合
                    store.dispatch('mcp:updateMcpList', renderMcpList(res, mcpCreater));
                    // 判断各个调起位的屏蔽状态
                    this.getActionFlag();
                    // 初始化拖拽按钮
                    dragButton.initDrag();
                    // 获取mcp实验数据 ios safari，静默调起，feed00-57596 {"slienceInvoke": 1440} 1440表示频控24小时
                    const mcplist = store.getState().mcpInvoke.mcpList;
                    const posTopItem = getMcpPos('pos_fusion_dt_topguid', mcplist);
                    const sidInfo = posTopItem && posTopItem.opt && posTopItem.opt[0].display_desc;
                    if (sidInfo) {
                        const time = +JSON.parse(sidInfo).slienceInvoke;
                        mcpMethod.slienceInvoke({time, scheme: posTopItem.opt[0].scheme});
                    }
                }
                catch (error) {}
            })
        }
    };

    /**
     * @abstract 更新各调起位置展现状态
     */
    getActionFlag() {
        const {mcpInvoke} = store.getState();
        for (const i in mcpInvoke.mcpList) {
            const {invokeMcp = {}, pos: invokePos = ''} = mcpInvoke.mcpList[i];

            if (invokeMcp.isBlock() && STATUS_POS_KEY[invokePos]) {
                console.log(invokePos, '::invokePos block');
                // 更新store各调起位置展现状态
                store.dispatch('mcp:updateIvkFlag', {
                    invokePos,
                });
            }
        }
    };
};

export default new requestMcp();
