/**
 * fusionPlayer store 
 */

export default {
    init({data = {}}: Record<string, any>) {
        const {
            content,
            type = 'dt',
            mount,
            dtshare_smfw: dtshareSmfw,
        } = data || {};
        // 根据type取正文数据
        const {
            image_items: dtImages = [],
            back_music: backMusic = {},
            origin: forwardCard = {},
            template,
        } = content?.[type] || {};

        let defRate = 1.5;

        // 屏效实验，调整为高/宽 1.1
        const {
            play_area_height_type: playAreaHeight
        } = dtshareSmfw || {};

        if (playAreaHeight === '1' || playAreaHeight === '3') {
            defRate = 1.1;
        }

        // 计算并增加高/宽参数，最大1.5
        dtImages.forEach((item: any) => {
            const {width, height} = item.original || {};
            let hwRatio;
            if (template === 'dtlanding') {
                // 旧动态不限比例
                hwRatio = height / width
            } else {
                // 新动态图片比例最大2:3
                hwRatio = Math.min((height / width), defRate);
                // 极端case：如果图片比例小于0.5，则设置为0.5
                if (hwRatio < 0.5) {
                    hwRatio = 0.5;
                }
            }
            item.hwRatio = `${(hwRatio * 100).toFixed(2)}%`;
        });

        return {
            dtImages,
            backMusic,
            // 完播卡
            playEndCard: data.playEndCard,
            // 挂载卡
            mountCard: mount || {},
            // 转发动态
            forwardCard,
        };
    },
};
