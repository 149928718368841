/**
 * @file share-page迁移的不同场景常量
 * 部分可能暂时未用到，根据情况陆续删除
*/

import {getQueries, uaInfo} from '@/lib/utils';
export const query2Json: {
    [key: string]: any;
} = getQueries() || {};

// 云配置
export const cloudConf = window.jsonData?.cloud_conf || {};

// 域名判断
const isMbdLite = location && location.host.indexOf('mbdlite') >= 0;
const isMbdTomas = location && location.host.indexOf('mbdtomas') >= 0;

// 产品线判断
export const isLite = query2Json._refluxos === 'a2' || query2Json._refluxos === 'i3' || false;
export const isTomas = query2Json._refluxos === 'a7' || query2Json._refluxos === 'i7' || false;
export const isHonor = query2Json._refluxos === 'a10' || false;

// 来源暂时屏蔽调起的
const blockCardList = ['a10'];
export const isBlockInvoke = blockCardList.includes(query2Json._refluxos);

// 微信 & 安卓 & ( 主版 || 极速版 & mbdlite的域名前缀 || 畅听版（原大字版） & mbdtomas的域名前缀 )
export const openWxOpenTag = uaInfo.isWeixin
                            && ((!isLite && !isTomas)
                                || (isLite && isMbdLite && !uaInfo.isIOS)
                                || (isTomas && isMbdTomas && !uaInfo.isIOS));

/**
 * @abstract 各矩阵定制配置
 */
export const envConfList = {
    // 荣耀白牌
    honor: {
        isMatch: isHonor,
        appName: 'bdhonorbrowser',
        scene: 'sharepage',
    },
    // 极速版
    lite: {
        isMatch: isLite,
        scene: 'sharepagelite',
        // 微信开放标签
        openWxOpenTag,
        wxTagAppid: 'wx6d2ad6ce3e1cd86e',
        // 请求mcp接口，使用的app_id
        mcpRequestAppid: 'wxc3999e3d983650ff',
        pkgName: 'com.baidu.searchbox.lite',
        // app config
        appName: 'baiduboxlite',
        appTitle: '百度极速版APP',
        appLogo: 'https://matrix-fe.cdn.bcebos.com/baiduboxlite/logo.png',
    },
    // 畅听版（原大字版）
    tomas: {
        isMatch: isTomas,
        scene: 'sharepagetomas',
        openWxOpenTag,
        wxTagAppid: 'wxa7bf69d174322fbb',
        mcpRequestAppid: 'wx3b356ac320c2d586',
        pkgName: 'com.baidu.searchbox.tomas',
        appName: 'tomas',
        appTitle: '百度畅听版APP',
        appLogo: 'https://b.bdstatic.com/searchbox/image/gcp/20240221/3987504992.png',
    },
    // 默认为主版-手百分享
    default: {
        isMatch: false,
        scene: 'sharepage',
        // 微信开放标签
        openWxOpenTag,
        wxTagAppid: 'wx3fcdd8310a136ff8',
        mcpRequestAppid: 'wx85d749f4a86cb9a9',
        pkgName: 'com.baidu.searchbox',
        // app config
        appName: 'baiduboxapp',
        appTitle: '百度APP',
        appLogo: 'https://b.bdstatic.com/searchbox/image/gcp/20250214/215827829.png',
    },
};

const getEnvConf = (configList: Record<string, any>) => {
    const target = Object.keys(configList).find(item => configList[item].isMatch) || 'default';

    return configList[target];
};

export const envConf = getEnvConf(envConfList);